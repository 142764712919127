import React from "react";
import Modal from "react-bootstrap/Modal";

const PortfolioCardModal = ({ isOpen, hideModal, data }) => {
  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        show={isOpen}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <button
            onClick={hideModal}
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
          
          </button>
          <div className="new-modal-box">
            <figure>
              <img loading="lazy" src={data?.img} alt="news" />
            </figure>
            <h3>{data?.title}</h3>
            <p>{data?.subTitle}</p>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default PortfolioCardModal;
